<template>
  <div class="rolesContentWrap">
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang='scss'>
.rolesContentWrap {
  background-color: #fff;
}
</style>